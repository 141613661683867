$primary: #175DDC;
$primary-accent: #1252A3;
$secondary: #CED4DA;
$secondary-alt: #1A3B66;
$success: #00A65A;
$info: #555555;
$warning: #BF7E16;
$danger: #DD4B39;
$white: #FFFFFF;

$theme-colors: (  
    "primary-accent": $primary-accent,
    "secondary-alt": $secondary-alt,
);

$body-bg: $white;
$body-color: #333333;

$font-family-sans-serif: 'Open Sans','Helvetica Neue',Helvetica,
    Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';

$h1-font-size: 1.7rem;
$h2-font-size: 1.3rem;
$h3-font-size: 1rem;
$h4-font-size: 1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

$small-font-size: 90%;
$font-size-lg: 1.15rem;
$code-font-size: 100%;

$navbar-padding-y: .75rem;
$grid-gutter-width: 20px;
$card-spacer-y: .6rem;

$list-group-item-padding-y: .6rem;
$list-group-active-color: $body-color;
$list-group-active-bg: $white;
$list-group-active-border-color: rgba(#000000, .125);

$dropdown-link-color: $body-color;
$dropdown-link-hover-bg: rgba(#000000, .06);
$dropdown-link-active-color: $dropdown-link-color;
$dropdown-link-active-bg: rgba(#000000, .1);
$dropdown-item-padding-x: 1rem;

$navbar-brand-font-size: 35px;
$navbar-brand-height: 35px;
$navbar-brand-padding-y: 0;
$navbar-dark-color: rgba($white, .7);
$navbar-dark-hover-color: rgba($white, .9);
$navbar-nav-link-padding-x: 0.8rem;

$input-bg: #FBFBFB;
$input-focus-bg: $white;
$input-disabled-bg: #E0E0E0;
$input-placeholder-color: #B4B4B4;

$table-accent-bg: rgba(#000000, .02);
$table-hover-bg: rgba(#000000, .03);

$modal-backdrop-opacity: 0.3;
$btn-font-weight: 600;
$lead-font-weight: normal;

$grid-breakpoints: (
  xs: 0,
  sm: 1px,
  md: 2px,
  lg: 3px,
  xl: 4px
);

$text-color: #333333;
$border-color: $secondary;

$fa-font-path: "~font-awesome/fonts";

// MFA Types for logo styling with no dark theme alternative

$mfaTypes: 0, 1, 2, 3, 4, 6;

// Theme Variables

$lightDangerHover: #C43421;

$darkPrimary: #6A99F0;
$darkPrimary-alt: #B4CCF9;
$darkDanger: #FF8D85;
$darkDangerHover: #FFBfBB;
$darkSuccess: #52E07C;
$darkWarning: #FFEB66;
$darkInfo: #A4B0C6;
$darkLinks: #6A99F0;
$darkGrey1: #BAC0CE;
$darkGrey2: #8D94A5;
$darkBlue1: #4C525F;
$darkBlue2: #3C424E;
$darkDarkBlue1: #2F343D;
$darkDarkBlue2: #1F242E;

$lightInputColor: #465057;
$lightInputPlaceholderColor: #B6B8B8;
$darkInputColor: $white;
$darkInputPlaceholderColor: $darkGrey1;

$themes: (
    light: (
        logoSuffix: 'dark',
        primary: $primary,
        primaryAlt: $primary-accent,
        danger: $danger,
        success: $success,
        warning: $warning,
        info: #343A40,
        textColor: $text-color,
        headingColor: #333333,
        darkTextColor: #495057,
        warningTextColor: $white,
        successTextColor: $white,
        dangerTextColor: $white,
        infoTextColor: $white,
        textMuted: #6C757D,
        backgroundColor: $body-bg,
        badgeDangerBackground: $danger,
        badgeDangerText: $white,
        badgeInfoBackground: #555555,
        badgeInfoText: $white,
        badgePrimaryBackground: $primary,
        badgePrimaryBackgroundHover: #134EB9,
        badgePrimaryText: $white,
        badgeSecondaryBackground: #CED4DA,
        badgeSecondaryText: #212529,
        bgPrimaryColor: $primary,
        bgLightColor: #F8F9FA,
        borderColor: $border-color,
        borderPrimaryColor: $primary,
        btnDanger: $danger,
        btnDangerHover: $lightDangerHover,
        btnDangerText: $white,
        btnLinkTextColor: $primary,
        btnLinkTextColorHover: #104097,
        btnOutlineDangerBackground: $input-bg,
        btnOutlineDangerBackgroundHover: $danger,
        btnOutlineDangerBorder: #CED4DA,
        btnOutlineDangerBorderHover: $danger,
        btnOutlineDangerText: $danger,
        btnOutlineDangerTextHover: $white,
        btnOutlinePrimaryBackground: $input-bg,
        btnOutlinePrimaryBackgroundHover: $primary,
        btnOutlinePrimaryBorder: #CED4DA,
        btnOutlinePrimaryBorderHover: $primary,
        btnOutlinePrimaryText: $primary,
        btnOutlinePrimaryTextHover: $white,
        btnOutlineSecondaryBackground: $input-bg,
        btnOutlineSecondaryBackgroundHover: #CED4DA,
        btnOutlineSecondaryBorder: #CED4DA,
        btnOutlineSecondaryBorderHover: #CED4DA,
        btnOutlineSecondaryText: #6C757D,
        btnOutlineSecondaryTextHover: #333333,
        btnPrimary: $primary,
        btnPrimaryBorderHover: #1249AE,
        btnPrimaryHover: #134EB9,
        btnPrimaryText: $white,
        btnSecondary: $secondary,
        btnSecondaryBorder: $secondary,
        btnSecondaryBorderHover: #B1BBC4,
        btnSecondaryHover: #B8C1CA,
        btnSecondaryText: #212529,
        btnSecondaryTextHover: #212529,
        calloutBackground: #FAFAFA,
        calloutColor: #212529,
        cdkDraggingBackground: $white,
        codeColor: #E83E8C,
        dropdownBackground: $white,
        dropdownDangerHover: #C43421,
        dropdownHover: rgba(0,0,0,0.06),
        dropdownTextColor: $text-color,
        dropdownTextMuted: #6C757D,
        focus: rgb(23 93 220 / 25%),
        footerBackgroundColor: #FBFBFB,
        foregroundColor: $white,
        headerColor: rgba(0,0,0,0.03),
        iconColor: #777777,
        iconHover: $body-color,
        imgFilter: invert(0) grayscale(0),
        inputBackgroundColor: #FBFBFB,
        inputBorderColor: $border-color,
        inputDisabledBackground: #E0E0E0,
        inputDisabledColor: #6C757D,
        inputPlaceholderColor: $lightInputPlaceholderColor,
        inputTextColor: $lightInputColor,
        layoutFrontendColor: #ECF0F5,
        learnMoreHover: #104097,
        linkColor: $primary,
        linkColorHover: #104097,
        linkWeight: 400,
        listItemActive: $text-color,
        listItemBorder: rgba(0,0,0,.125),
        listItemColor: $primary,
        listItemColorHover: #104097,
        loadingSvg: url('../images/loading.svg'),
        navActiveBackground: $white,
        navActiveWeight: 600,
        navBackground: $primary,
        navBackgroundAlt: $secondary-alt,
        navOrgBackgroundColor: #FBFBFB,
        navWeight: 600,
        pwLetter: $text-color,
        pwNumber: #007FDE,
        pwSpecial: #C40800,
        pwStrengthBackground: #E9ECEF,
        registerHeadingColor: $white,
        separator: $secondary,
        separatorHr: rgb(0,0,0,0.1),
        tableColorHover: #333333,
        tableLinkColor: $primary,
        tableLinkColorHover: #104097,
        tableRowHover: rgba(0,0,0,0.03),
        tableSeparator: #DEE2E6,
        browserInputIconsFilter: invert(0),
        mfaLogoSuffix: '.png'
    ),
    dark: (
        logoSuffix: 'white',
        primary: $darkPrimary,
        primaryAlt: $darkPrimary-alt,
        danger: $darkDanger,
        success: $darkSuccess,
        warning: $darkWarning,
        info: $darkInfo,
        textColor: $darkGrey1,
        headingColor: $white,
        darkTextColor: $darkDarkBlue2,
        warningTextColor: $darkDarkBlue2,
        successTextColor: $darkDarkBlue2,
        dangerTextColor: $darkDarkBlue2,
        infoTextColor: $darkDarkBlue2,
        textMuted: $darkGrey1,
        backgroundColor: $darkDarkBlue2,
        badgeDangerBackground: $darkDanger,
        badgeDangerText: $darkDarkBlue2,
        badgeInfoBackground: $darkInfo,
        badgeInfoText: $darkDarkBlue2,
        badgePrimaryBackground: $darkLinks,
        badgePrimaryBackgroundHover: $darkPrimary-alt,
        badgePrimaryText: $darkDarkBlue2,
        badgeSecondaryBackground: $darkDarkBlue2,
        badgeSecondaryText: $white,
        bgPrimaryColor: $darkPrimary,
        bgLightColor: $darkDarkBlue2,
        borderColor: $darkBlue1,
        borderPrimaryColor: $darkPrimary,
        btnDanger: $darkDanger,
        btnDangerHover: $darkDangerHover,
        btnDangerText: $darkDarkBlue2,
        btnLinkText: $white,
        btnLinkTextHover: $darkGrey1,
        btnOutlineDangerBackground: $darkDanger,
        btnOutlineDangerBackgroundHover: $darkDangerHover,
        btnOutlineDangerBorder: $darkDanger,
        btnOutlineDangerBorderHover: $darkDangerHover,
        btnOutlineDangerText: $darkDarkBlue2,
        btnOutlineDangerTextHover: $darkDarkBlue2,
        btnOutlinePrimaryBackground: $darkPrimary,
        btnOutlinePrimaryBackgroundHover: $darkPrimary-alt,
        btnOutlinePrimaryBorder: $darkPrimary,
        btnOutlinePrimaryBorderHover: $darkPrimary-alt,
        btnOutlinePrimaryText: $darkDarkBlue2,
        btnOutlinePrimaryTextHover: $darkDarkBlue2,
        btnOutlineSecondaryBackground: transparent,
        btnOutlineSecondaryBackgroundHover: transparent,
        btnOutlineSecondaryBorder: $darkGrey1,
        btnOutlineSecondaryBorderHover: $darkGrey2,
        btnOutlineSecondaryText: $white,
        btnOutlineSecondaryTextHover: $darkGrey2,
        btnPrimary: $darkLinks,
        btnPrimaryBorderHover: $darkPrimary-alt,
        btnPrimaryHover: $darkPrimary-alt,
        btnPrimaryText: $darkDarkBlue2,
        btnSecondary: transparent,
        btnSecondaryBorder: $darkGrey1,
        btnSecondaryBorderHover: $darkGrey2,
        btnSecondaryHover: transparent,
        btnSecondaryText: $white,
        btnSecondaryTextHover: $darkGrey2,
        calloutBackground: $darkBlue2,
        calloutColor: $white,
        cdkDraggingBackground: $darkDarkBlue1,
        codeColor: #E83E8C,
        dropdownBackground: $darkDarkBlue1,
        dropdownDangerHover: $darkDangerHover,
        dropdownHover: rgba(255, 255, 255, 0.03),
        dropdownTextColor: $white,
        dropdownTextMuted: #BEC6CF,
        focus: rgb(106 153 240 / 25%),
        footerBackgroundColor: $darkBlue1,
        foregroundColor: $darkDarkBlue1,
        headerColor: $darkBlue1,
        iconColor: #777777,
        iconHover: $darkGrey2,
        imgFilter: invert(1) grayscale(1),
        inputBackgroundColor: $darkDarkBlue1,
        inputBorderColor: $darkGrey1,
        inputDisabledBackground: $darkBlue2,
        inputDisabledColor: $darkGrey1,
        inputPlaceholderColor: $darkInputPlaceholderColor,
        inputTextColor: $darkInputColor,
        layoutFrontendColor: $darkDarkBlue2,
        learnMoreHover: $darkPrimary-alt,
        linkColor: $darkLinks,
        linkColorHover: $darkLinks,
        linkWeight: 600,
        listItemActive: $darkPrimary,
        listItemBorder: $darkBlue1,
        listItemColor: $white,
        listItemColorHover: $white,
        loadingSvg: url('../images/loading-white.svg'),
        navActiveBackground: $darkDarkBlue1,
        navActiveWeight: 600,
        navBackground: $darkDarkBlue1,
        navBackgroundAlt: $darkDarkBlue1,
        navOrgBackgroundColor: #161C26,
        navWeight: 400,
        pwLetter: $white,
        pwNumber: #52BDFB,
        pwSpecial: #FF7C70,
        pwStrengthBackground: $darkBlue2,
        registerHeadingColor: $white,
        separator: $darkBlue1,
        separatorHr: $darkBlue1,
        tableColorHover: $darkGrey1,
        tableLinkColor: $white,
        tableLinkColorHover: $white,
        tableRowHover: rgba(255, 255, 255, 0.03),
        tableSeparator: $darkBlue1,
        browserInputIconsFilter: invert(1),
        mfaLogoSuffix: '-w.png'
    ),
);

@mixin themify($themes: $themes) {
    @each $theme, $map in $themes {
        html.theme_#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
};

@function themed($key) {
    @return map-get($theme-map, $key);
};
